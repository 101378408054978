import '@styles/main.scss';
import $ from 'jquery';
import EventSwiper from './components/event-swiper';
import Swiper, {Navigation} from 'swiper';
import 'swiper/css';
import CaseStudySwiper from './components/case-study-swiper';

class App {

    constructor() {

        jQuery('#toggle').on("click", function() {
            jQuery(this).toggleClass('active');
            jQuery('#overlay').toggleClass('open');
        });

        new EventSwiper();
        new CaseStudySwiper();
        this.Menu();
        this.MobileCatSidebar();
    }

    Menu() {
        const menuItem = document.querySelectorAll('.parent-category');

        menuItem.forEach(item => {
            const clickElement = item.querySelector('.parent-cat-link');
            clickElement?.addEventListener('click', function() {
                const subCat = item.querySelector('.sub-category-list');
                subCat?.classList.toggle('is-active'); 
                clickElement.classList.toggle('is-active');
            })
        })

    }

    MobileCatSidebar() {
        const trigger = document.getElementById('product-cat-sidebar__title-icon');
        const sidebar = document.getElementById('product-cat-list-dropdown');

        trigger?.addEventListener('click', function() {
            sidebar?.classList.toggle('is-active');
        })
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});

