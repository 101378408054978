import Swiper, {Navigation} from 'swiper';

import 'swiper/css';

export default class EventSwiper {
    constructor() {
        const wrapper = document.querySelectorAll('.events-type-wrapper');

        if(!wrapper.length) {
            return;
        }

        wrapper.forEach((wrap) => {
            const eventSwiper = new Swiper('.events-swiper', {
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                },

                slidesPerView: 1,
                spaceBetween: 30,
                loop: true,

                breakpoints: {
                    1150: {
                        slidesPerView: 4
                    },

                    900: {
                        slidesPerView: 3
                    },

                    765: {
                        slidesPerView: 2
                    },

                },

                modules: [Navigation]
            })
        })
    }
}