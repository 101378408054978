import Swiper, {Navigation} from 'swiper';

import 'swiper/css';

export default class CaseStudySwiper {
    constructor() {
        const wrapper = document.querySelectorAll('.case-study-wrapper');

        if(!wrapper.length) {
            return;
        }

        wrapper.forEach((wrap) => {
            const caseStudySwiper = new Swiper('.case-study-swiper', {
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                },

                slidesPerView: 1,
                spaceBetween: 20,
                loop: true,

                breakpoints: {
                   1025: {
                    slidesPerView: 2
                   } 
                },

                modules: [Navigation]
            })
        })
    }
}